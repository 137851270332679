<template>
  <div class="text-center">
    <v-dialog v-model="compDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $vuetify.lang.t('$vuetify.messages.wait') }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },
  computed: {
    compDialog: {
      get: function() {
        return this.dialog
      },
      set: function(newValue) {
        this.$emit('update:dialog', newValue)
      }
    }
  }
}
</script>
